

import * as Recoil from "recoil";

var recommendationsState = Recoil.atom({
      key: "recommendationsState",
      default: []
    });

export {
  recommendationsState ,
  
}
/* recommendationsState Not a pure module */
