

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Recoil from "recoil";
import * as MaterialUi from "@jsiebern/bs-material-ui/src/MaterialUi.bs.js";
import * as Top$Spotigen from "./top/Top.bs.js";
import * as Genres$Spotigen from "./genres/Genres.bs.js";
import * as Search$Spotigen from "./search/Search.bs.js";
import * as Core from "@material-ui/core";
import * as Selection$Spotigen from "./selection/Selection.bs.js";
import * as SelectionState$Spotigen from "../../state/SelectionState.bs.js";
import * as RecommendationsState$Spotigen from "../../state/RecommendationsState.bs.js";

function Creation(Props) {
  var spotifyClient = Props.spotifyClient;
  var onSeedTooSmall = Props.onSeedTooSmall;
  var match = React.useState(function () {
        return 0;
      });
  var setSelectedTab = match[1];
  var selectedTab = match[0];
  var match$1 = React.useState(function () {
        return [];
      });
  var setGenres = match$1[1];
  var match$2 = React.useState(function () {
        return [];
      });
  var setTopTracks = match$2[1];
  var match$3 = React.useState(function () {
        return [];
      });
  var setTopArtists = match$3[1];
  var selected = Recoil.useRecoilValue(SelectionState$Spotigen.selectionState);
  var match$4 = Recoil.useRecoilState(RecommendationsState$Spotigen.recommendationsState);
  var setRecommendations = match$4[1];
  React.useEffect((function () {
          Future.get(Curry._1(spotifyClient.getGenres, undefined), (function (response) {
                  if (response.TAG === /* Ok */0) {
                    var results = response._0;
                    return Curry._1(setGenres, (function (param) {
                                  return results;
                                }));
                  }
                  console.log(response._0);
                  
                }));
          Future.get(Curry._1(spotifyClient.getTopTracks, undefined), (function (response) {
                  if (response.TAG === /* Ok */0) {
                    var results = response._0;
                    return Curry._1(setTopTracks, (function (param) {
                                  return results;
                                }));
                  }
                  console.log(response._0);
                  
                }));
          Future.get(Curry._1(spotifyClient.getTopArtists, undefined), (function (response) {
                  if (response.TAG === /* Ok */0) {
                    var results = response._0;
                    return Curry._1(setTopArtists, (function (param) {
                                  return results;
                                }));
                  }
                  console.log(response._0);
                  
                }));
          
        }), []);
  var generate = function (param) {
    return Future.get(Curry._1(spotifyClient.getRecommendation, selected), (function (response) {
                  if (response.TAG === /* Ok */0) {
                    var results = response._0;
                    if (results.length !== 0) {
                      return Curry._1(setRecommendations, (function (param) {
                                    return results;
                                  }));
                    } else {
                      return Curry._1(onSeedTooSmall, undefined);
                    }
                  }
                  console.log(response._0);
                  
                }));
  };
  var selectTab = function (param, newTab) {
    return Curry._1(setSelectedTab, (function (param) {
                  return MaterialUi.anyUnpack(newTab);
                }));
  };
  var tmp;
  switch (selectedTab) {
    case 0 :
        tmp = React.createElement(Search$Spotigen.make, {
              spotifyClient: spotifyClient
            });
        break;
    case 1 :
        tmp = React.createElement(Top$Spotigen.make, {
              items: match$2[0]
            });
        break;
    case 2 :
        tmp = React.createElement(Top$Spotigen.make, {
              items: match$3[0]
            });
        break;
    case 3 :
        tmp = React.createElement(Genres$Spotigen.make, {
              items: match$1[0]
            });
        break;
    default:
      tmp = null;
  }
  return React.createElement("div", undefined, React.createElement(Core.Typography, {
                  children: "Get started",
                  className: "spacing",
                  variant: "h4"
                }), React.createElement(Core.Typography, {
                  children: "Choose tracks, genres and artists as a base for your recommendations"
                }), React.createElement(Core.Tabs, {
                  children: null,
                  className: "spacing",
                  indicatorColor: "primary",
                  onChange: selectTab,
                  scrollButtons: "on",
                  textColor: "primary",
                  value: selectedTab,
                  variant: "scrollable"
                }, React.createElement(Core.Tab, {
                      label: "Search"
                    }), React.createElement(Core.Tab, {
                      label: "Top tracks"
                    }), React.createElement(Core.Tab, {
                      label: "Top artists"
                    }), React.createElement(Core.Tab, {
                      label: "Genres"
                    })), tmp, React.createElement(Core.Typography, {
                  children: "Your selection",
                  variant: "h4"
                }), React.createElement(Selection$Spotigen.make, {}), React.createElement(Core.Button, {
                  onClick: generate,
                  children: "Generate recommendations",
                  color: "primary",
                  disabled: selected.length === 0,
                  variant: "contained"
                }));
}

var make = Creation;

export {
  make ,
  
}
/* react Not a pure module */
