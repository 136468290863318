

import * as Future from "rescript-future/src/Future.bs.js";
import * as Js_dict from "bs-platform/lib/es6/js_dict.js";
import * as $$Request from "rescript-request/src/Request.bs.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as SpotifyEnv$Spotigen from "../utils/SpotifyEnv.bs.js";
import * as ModelMapper$Spotigen from "../utils/ModelMapper.bs.js";
import * as RequestMapper$Spotigen from "../utils/RequestMapper.bs.js";

function mapTrackResponseToItem(response) {
  var status = response.status;
  if (status !== 200) {
    return {
            TAG: /* Error */1,
            _0: /* ResponseError */{
              _0: {
                message: "Failed request with $status"
              }
            }
          };
  }
  var responseBody = response.response;
  if (responseBody !== undefined) {
    return {
            TAG: /* Ok */0,
            _0: Belt_Array.map(Belt_Array.map(responseBody.items, ModelMapper$Spotigen.mapSearchTrackToItemTrack), (function (t) {
                    return {
                            TAG: /* Track */2,
                            _0: t
                          };
                  }))
          };
  } else {
    return {
            TAG: /* Error */1,
            _0: /* Empty */0
          };
  }
}

function mapArtistResponseToItem(response) {
  var status = response.status;
  if (status !== 200) {
    return {
            TAG: /* Error */1,
            _0: /* ResponseError */{
              _0: {
                message: "Failed request with $status"
              }
            }
          };
  }
  var responseBody = response.response;
  if (responseBody !== undefined) {
    return {
            TAG: /* Ok */0,
            _0: Belt_Array.map(Belt_Array.map(responseBody.items, ModelMapper$Spotigen.mapSearchArtistToItemArtist), (function (a) {
                    return {
                            TAG: /* Artist */1,
                            _0: a
                          };
                  }))
          };
  } else {
    return {
            TAG: /* Error */1,
            _0: /* Empty */0
          };
  }
}

var queryParam = String(new URLSearchParams({
          limit: 10
        }));

function initTopTracks(token) {
  var authHeader = Js_dict.fromArray([[
          "Authorization",
          "Bearer " + token
        ]]);
  return function (param) {
    return Future.mapResult(Future.mapError($$Request.make(SpotifyEnv$Spotigen.topTracksEndpoint + queryParam, undefined, /* JsonAsAny */5, undefined, Caml_option.some(authHeader), undefined, undefined, undefined, undefined, undefined), true, RequestMapper$Spotigen.mapError), true, mapTrackResponseToItem);
  };
}

function initTopArtist(token) {
  var authHeader = Js_dict.fromArray([[
          "Authorization",
          "Bearer " + token
        ]]);
  return function (param) {
    return Future.mapResult(Future.mapError($$Request.make(SpotifyEnv$Spotigen.topArtistsEndpoint + queryParam, undefined, /* JsonAsAny */5, undefined, Caml_option.some(authHeader), undefined, undefined, undefined, undefined, undefined), true, RequestMapper$Spotigen.mapError), true, mapArtistResponseToItem);
  };
}

export {
  mapTrackResponseToItem ,
  mapArtistResponseToItem ,
  queryParam ,
  initTopTracks ,
  initTopArtist ,
  
}
/* queryParam Not a pure module */
