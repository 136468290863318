

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Recoil from "recoil";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Core from "@material-ui/core";
import * as ItemUtil$Spotigen from "../../../util/ItemUtil.bs.js";
import * as SelectionItem$Spotigen from "./SelectionItem.bs.js";
import * as SelectionState$Spotigen from "../../../state/SelectionState.bs.js";

function $$Selection(Props) {
  var match = Recoil.useRecoilState(SelectionState$Spotigen.selectionState);
  var setItems = match[1];
  var items = match[0];
  var removeItem = function (itemId) {
    return Curry._1(setItems, (function (currentSelection) {
                  return Belt_Array.keep(currentSelection, (function (item) {
                                var match = ItemUtil$Spotigen.extractItemInfo(item);
                                return match[1] !== itemId;
                              }));
                }));
  };
  return React.createElement("div", {
              className: "spacing"
            }, items.length !== 0 ? Belt_Array.map(items, (function (item) {
                      var match = ItemUtil$Spotigen.extractItemInfo(item);
                      var id = match[1];
                      return React.createElement(SelectionItem$Spotigen.make, {
                                  item: item,
                                  id: id,
                                  onRemove: removeItem,
                                  key: id
                                });
                    })) : React.createElement(Core.Typography, {
                    children: "Select at least one item to generate recommendations",
                    style: {
                      color: "grey"
                    }
                  }));
}

var make = $$Selection;

export {
  make ,
  
}
/* react Not a pure module */
