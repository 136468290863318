

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Recoil from "recoil";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Hooks$Spotigen from "../../../util/Hooks.bs.js";
import * as Core from "@material-ui/core";
import * as ItemUtil$Spotigen from "../../../util/ItemUtil.bs.js";
import * as SearchItem$Spotigen from "./SearchItem.bs.js";
import * as MaterialUi_TextField from "@jsiebern/bs-material-ui/src/MaterialUi_TextField.bs.js";
import * as SelectionState$Spotigen from "../../../state/SelectionState.bs.js";

function Search(Props) {
  var spotifyClient = Props.spotifyClient;
  var match = React.useState(function () {
        return "";
      });
  var setSearchTerm = match[1];
  var searchTerm = match[0];
  var debouncedSearchTerm = Hooks$Spotigen.useDebounce(searchTerm, 400);
  var match$1 = React.useState(function () {
        return [];
      });
  var setItems = match$1[1];
  var items = match$1[0];
  var selected = Recoil.useRecoilValue(SelectionState$Spotigen.selectionState);
  var changeSearchTerm = function ($$event) {
    return Curry._1(setSearchTerm, $$event.target.value);
  };
  React.useEffect((function () {
          if (debouncedSearchTerm === "") {
            Curry._1(setItems, (function (param) {
                    return [];
                  }));
          } else {
            Future.get(Curry._1(spotifyClient.getSearch, debouncedSearchTerm), (function (response) {
                    if (response.TAG === /* Ok */0) {
                      var results = response._0;
                      return Curry._1(setItems, (function (param) {
                                    return results;
                                  }));
                    }
                    console.log(response._0);
                    
                  }));
          }
          
        }), [debouncedSearchTerm]);
  return React.createElement("div", undefined, React.createElement("form", {
                  autoComplete: "off"
                }, React.createElement(Core.TextField, {
                      className: "input",
                      onChange: changeSearchTerm,
                      placeholder: "Search",
                      type: "search",
                      value: MaterialUi_TextField.Value.string(searchTerm),
                      variant: "outlined"
                    })), React.createElement("div", {
                  className: "spacing"
                }, items.length !== 0 ? Belt_Array.map(items, (function (item) {
                          var match = ItemUtil$Spotigen.extractItemInfo(item);
                          var id = match[1];
                          var added = Belt_Array.some(selected, (function (itemToCheck) {
                                  var match = ItemUtil$Spotigen.extractItemInfo(itemToCheck);
                                  return match[1] === id;
                                }));
                          return React.createElement(SearchItem$Spotigen.make, {
                                      item: item,
                                      added: added,
                                      key: id
                                    });
                        })) : React.createElement(Core.Typography, {
                        children: "Enter a search term to select items",
                        style: {
                          color: "grey"
                        }
                      })));
}

var make = Search;

export {
  make ,
  
}
/* react Not a pure module */
