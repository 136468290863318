

import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";

function getSmallestImage(images) {
  var startImg = {
    url: "",
    height: 999999
  };
  if (images !== undefined) {
    return Belt_Array.reduce(images, startImg, (function (a, b) {
                  if (a.height < b.height) {
                    return a;
                  } else {
                    return b;
                  }
                }));
  } else {
    return startImg;
  }
}

function mapSearchArtistToItemArtist(artist) {
  return {
          id: artist.id,
          name: artist.name,
          image: getSmallestImage(artist.images).url
        };
}

function mapSearchTrackToItemTrack(track) {
  return {
          id: track.id,
          artists: Belt_Array.map(track.artists, mapSearchArtistToItemArtist),
          name: track.name,
          image: getSmallestImage(track.album.images).url,
          uri: track.uri
        };
}

export {
  getSmallestImage ,
  mapSearchArtistToItemArtist ,
  mapSearchTrackToItemTrack ,
  
}
/* No side effect */
