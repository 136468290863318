

import * as Recoil from "recoil";

var selectionState = Recoil.atom({
      key: "selectionState",
      default: []
    });

export {
  selectionState ,
  
}
/* selectionState Not a pure module */
