

import * as Future from "rescript-future/src/Future.bs.js";
import * as Js_dict from "bs-platform/lib/es6/js_dict.js";
import * as $$Request from "rescript-request/src/Request.bs.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as SpotifyEnv$Spotigen from "../utils/SpotifyEnv.bs.js";
import * as ModelMapper$Spotigen from "../utils/ModelMapper.bs.js";
import * as RequestMapper$Spotigen from "../utils/RequestMapper.bs.js";

function mapResponseToItem(response) {
  var status = response.status;
  if (status !== 200) {
    return {
            TAG: /* Error */1,
            _0: /* ResponseError */{
              _0: {
                message: "Failed request with $status"
              }
            }
          };
  }
  var response$1 = response.response;
  if (response$1 === undefined) {
    return {
            TAG: /* Error */1,
            _0: /* Empty */0
          };
  }
  var artists = Belt_Array.map(Belt_Array.map(response$1.artists.items, ModelMapper$Spotigen.mapSearchArtistToItemArtist), (function (a) {
          return {
                  TAG: /* Artist */1,
                  _0: a
                };
        }));
  var tracks = Belt_Array.map(Belt_Array.map(response$1.tracks.items, ModelMapper$Spotigen.mapSearchTrackToItemTrack), (function (t) {
          return {
                  TAG: /* Track */2,
                  _0: t
                };
        }));
  var firstArtist = Belt_Array.get(artists, 0);
  var searchResults = firstArtist !== undefined ? Belt_Array.concatMany([
          [firstArtist],
          tracks,
          Belt_Array.sliceToEnd(artists, 1)
        ]) : tracks;
  return {
          TAG: /* Ok */0,
          _0: searchResults
        };
}

function init(token) {
  var authHeader = Js_dict.fromArray([[
          "Authorization",
          "Bearer " + token
        ]]);
  return function (query) {
    var queryParam = String(new URLSearchParams({
              q: query,
              type: "artist,track",
              limit: 5
            }));
    return Future.mapResult(Future.mapError($$Request.make(SpotifyEnv$Spotigen.searchUrl + queryParam, undefined, /* JsonAsAny */5, undefined, Caml_option.some(authHeader), undefined, undefined, undefined, undefined, undefined), true, RequestMapper$Spotigen.mapError), true, mapResponseToItem);
  };
}

export {
  mapResponseToItem ,
  init ,
  
}
/* SpotifyEnv-Spotigen Not a pure module */
