

import * as React from "react";
import * as Recoil from "recoil";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as ItemUtil$Spotigen from "../../../util/ItemUtil.bs.js";
import * as SearchItem$Spotigen from "../search/SearchItem.bs.js";
import * as SelectionState$Spotigen from "../../../state/SelectionState.bs.js";

function Top(Props) {
  var items = Props.items;
  var selected = Recoil.useRecoilValue(SelectionState$Spotigen.selectionState);
  return React.createElement("div", {
              className: "spacing"
            }, Belt_Array.map(Belt_Array.map(items, ItemUtil$Spotigen.extractItemInfo), (function (param) {
                    var id = param[1];
                    var added = Belt_Array.some(selected, (function (itemToCheck) {
                            var match = ItemUtil$Spotigen.extractItemInfo(itemToCheck);
                            return match[1] === id;
                          }));
                    return React.createElement(SearchItem$Spotigen.make, {
                                item: param[0],
                                added: added,
                                key: id
                              });
                  })));
}

var make = Top;

export {
  make ,
  
}
/* react Not a pure module */
