

import * as Recoil from "recoil";

var tokenState = Recoil.atom({
      key: "tokenState",
      default: ""
    });

export {
  tokenState ,
  
}
/* tokenState Not a pure module */
