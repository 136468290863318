

import * as React from "react";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";

import './Person.css';
;

function Person(Props) {
  var name = Props.name;
  var image = Props.image;
  var item = Props.item;
  return React.createElement("div", {
              className: "person"
            }, image !== undefined ? React.createElement(Core.Avatar, {
                    alt: name,
                    src: image
                  }) : React.createElement(Core.Avatar, {
                    children: name[0]
                  }), React.createElement("div", {
                  className: "person-name"
                }, item !== undefined ? Caml_option.valFromOption(item) : React.createElement(Core.Typography, {
                        children: name
                      })));
}

var make = Person;

export {
  make ,
  
}
/*  Not a pure module */
