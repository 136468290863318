

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Recoil from "recoil";
import * as Lab from "@material-ui/lab";
import * as MaterialUi_Types from "@jsiebern/bs-material-ui/src/MaterialUi_Types.bs.js";
import * as Core from "@material-ui/core";
import * as Creation$Spotigen from "../creation/Creation.bs.js";
import * as UserState$Spotigen from "../../state/UserState.bs.js";
import * as TokenState$Spotigen from "../../state/TokenState.bs.js";
import * as SpotifyService$Spotigen from "../../services/SpotifyService.bs.js";
import * as Recommendations$Spotigen from "../recommendations/Recommendations.bs.js";
import * as RecommendationsState$Spotigen from "../../state/RecommendationsState.bs.js";

function Dashboard(Props) {
  var token = Recoil.useRecoilValue(TokenState$Spotigen.tokenState);
  var recommendations = Recoil.useRecoilValue(RecommendationsState$Spotigen.recommendationsState);
  var match = Recoil.useRecoilState(UserState$Spotigen.userState);
  var setUser = match[1];
  var match$1 = React.useState(function () {
        return "";
      });
  var setSnackbarMessage = match$1[1];
  var snackbarMessage = match$1[0];
  var spotifyClient = SpotifyService$Spotigen.init(token);
  React.useEffect((function () {
          Future.get(Curry._1(spotifyClient.getUser, undefined), (function (response) {
                  if (response.TAG === /* Ok */0) {
                    var user = response._0;
                    return Curry._1(setUser, (function (param) {
                                  return user;
                                }));
                  }
                  console.log(response._0);
                  
                }));
          
        }), []);
  var handleAlertClose = function (param) {
    return Curry._1(setSnackbarMessage, (function (param) {
                  return "";
                }));
  };
  var handleClose = function (param, param$1) {
    return Curry._1(setSnackbarMessage, (function (param) {
                  return "";
                }));
  };
  var onPlaylistCreated = function (param) {
    return Curry._1(setSnackbarMessage, (function (param) {
                  return "PLAYLIST_CREATED";
                }));
  };
  var onSeedTooSmall = function (param) {
    return Curry._1(setSnackbarMessage, (function (param) {
                  return "NO_CONTENT";
                }));
  };
  var match$2;
  switch (snackbarMessage) {
    case "NO_CONTENT" :
        match$2 = [
          "Error",
          "More seed items required"
        ];
        break;
    case "PLAYLIST_CREATED" :
        match$2 = [
          "Success",
          "Playlist created"
        ];
        break;
    default:
      match$2 = [
        "Error",
        "Unknown error"
      ];
  }
  return React.createElement("div", undefined, recommendations.length !== 0 ? React.createElement(Recommendations$Spotigen.make, {
                    spotifyClient: spotifyClient,
                    onPlaylistCreated: onPlaylistCreated
                  }) : React.createElement(Creation$Spotigen.make, {
                    spotifyClient: spotifyClient,
                    onSeedTooSmall: onSeedTooSmall
                  }), React.createElement(Core.Snackbar, {
                  autoHideDuration: MaterialUi_Types.$$Number.$$int(6000),
                  children: React.createElement(Lab.Alert, {
                        children: match$2[1],
                        onClose: handleAlertClose,
                        severity: (function () {
                              switch (match$2[0]) {
                                case "Error" :
                                    return "error";
                                case "Info" :
                                    return "info";
                                case "Success" :
                                    return "success";
                                case "Warning" :
                                    return "warning";
                                
                              }
                            })()
                      }),
                  onClose: handleClose,
                  open: snackbarMessage.length !== 0
                }));
}

var make = Dashboard;

export {
  make ,
  
}
/* react Not a pure module */
