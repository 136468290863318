

import * as Recoil from "recoil";

var userState = Recoil.atom({
      key: "userState",
      default: {
        id: "guest",
        name: "Guest",
        image: ""
      }
    });

export {
  userState ,
  
}
/* userState Not a pure module */
