

import * as Caml_option from "bs-platform/lib/es6/caml_option.js";

function mapError(error) {
  if (error === "Timeout") {
    return /* Timeout */2;
  } else {
    return /* NetworkError */1;
  }
}

function emptyToError(param) {
  var response = param.response;
  if (response !== undefined) {
    return {
            TAG: /* Ok */0,
            _0: Caml_option.valFromOption(response)
          };
  } else {
    return {
            TAG: /* Error */1,
            _0: /* Empty */0
          };
  }
}

export {
  mapError ,
  emptyToError ,
  
}
/* No side effect */
