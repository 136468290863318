

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Recoil from "recoil";
import * as Auth$Spotigen from "../auth/Auth.bs.js";
import * as About$Spotigen from "../about/About.bs.js";
import * as Header$Spotigen from "../header/Header.bs.js";
import * as Core from "@material-ui/core";
import * as Dashboard$Spotigen from "../dashboard/Dashboard.bs.js";
import * as TokenState$Spotigen from "../../state/TokenState.bs.js";
import * as MaterialUi_Container from "@jsiebern/bs-material-ui/src/MaterialUi_Container.bs.js";
import * as AuthorizationService$Spotigen from "../../services/authorization/AuthorizationService.bs.js";

function MainContainer(Props) {
  var match = Recoil.useRecoilState(TokenState$Spotigen.tokenState);
  var setToken = match[1];
  var token = match[0];
  React.useEffect((function () {
          var token = AuthorizationService$Spotigen.getTokenFromCallback(undefined);
          if (token !== undefined) {
            Curry._1(setToken, (function (param) {
                    return token;
                  }));
          } else {
            Curry._1(setToken, (function (param) {
                    return "";
                  }));
          }
          
        }), []);
  return React.createElement("div", {
              className: "main-container"
            }, React.createElement("div", {
                  className: "content"
                }, React.createElement(Header$Spotigen.make, {
                      authenticated: token.length !== 0
                    }), React.createElement(Core.Container, {
                      children: token.length !== 0 ? React.createElement(Dashboard$Spotigen.make, {}) : React.createElement(Auth$Spotigen.make, {}),
                      maxWidth: MaterialUi_Container.MaxWidth.sm
                    })), React.createElement(About$Spotigen.make, {}));
}

var make = MainContainer;

export {
  make ,
  
}
/* react Not a pure module */
