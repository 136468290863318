


function removeUrlAnchor(param) {
  var url = window.location.origin;
  console.log(url);
  window.history.replaceState(window.history.state, "", url);
  
}

export {
  removeUrlAnchor ,
  
}
/* No side effect */
