

import * as V4 from "uuid/v4";

var clientId = "2e53e2a82ee64c4dbf48f4936ae1bb02";

var stateIdKey = "sessionId";

var baseUrl = "https://api.spotify.com/v1";

var searchUrl = "https://api.spotify.com/v1/search?";

var genresUrl = "https://api.spotify.com/v1/recommendations/available-genre-seeds";

var recommendationUrl = "https://api.spotify.com/v1/recommendations?";

var userUrl = "https://api.spotify.com/v1/me";

var usersUrl = "https://api.spotify.com/v1/users/";

var playlistsUrl = "https://api.spotify.com/v1/playlists/";

var topArtistsEndpoint = "https://api.spotify.com/v1/me/top/artists?";

var topTracksEndpoint = "https://api.spotify.com/v1/me/top/tracks?";

function stateId(param) {
  var sessionId = localStorage.getItem(stateIdKey);
  if (sessionId !== null) {
    return sessionId;
  }
  var uuid = V4();
  localStorage.setItem(stateIdKey, uuid);
  return uuid;
}

var authParams = String(new URLSearchParams({
          client_id: clientId,
          response_type: "token",
          redirect_uri: process.env.NODE_ENV === "development" ? "http://localhost:3000/" : "https://spotigen.bitvictory.dev",
          scope: "playlist-modify-private,user-top-read",
          state: stateId(undefined)
        }));

var authorizeUrl = "https://accounts.spotify.com/authorize?" + authParams;

var tokenKey = "accessToken";

var playlistsEndpoint = "/playlists";

var tracksEndpoint = "/tracks?";

export {
  clientId ,
  stateIdKey ,
  tokenKey ,
  baseUrl ,
  searchUrl ,
  genresUrl ,
  recommendationUrl ,
  userUrl ,
  usersUrl ,
  playlistsEndpoint ,
  playlistsUrl ,
  tracksEndpoint ,
  topArtistsEndpoint ,
  topTracksEndpoint ,
  stateId ,
  authParams ,
  authorizeUrl ,
  
}
/* authParams Not a pure module */
