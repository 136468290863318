

import * as React from "react";
import * as Core from "@material-ui/core";
import * as UserComponent$Spotigen from "../user/UserComponent.bs.js";

import './Header.css';
;

function Header(Props) {
  var authenticated = Props.authenticated;
  return React.createElement("div", {
              className: "header"
            }, React.createElement(Core.Typography, {
                  children: "spotigen"
                }), authenticated ? React.createElement(UserComponent$Spotigen.make, {}) : null);
}

var make = Header;

export {
  make ,
  
}
/*  Not a pure module */
