

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Recoil from "recoil";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Core from "@material-ui/core";
import * as ItemUtil$Spotigen from "../../util/ItemUtil.bs.js";
import * as UserState$Spotigen from "../../state/UserState.bs.js";
import * as MaterialUi_TextField from "@jsiebern/bs-material-ui/src/MaterialUi_TextField.bs.js";
import * as ItemComponent$Spotigen from "../item/ItemComponent.bs.js";
import ArrowBack from "@material-ui/icons/ArrowBack";
import * as RecommendationsState$Spotigen from "../../state/RecommendationsState.bs.js";

function Recommendations(Props) {
  var spotifyClient = Props.spotifyClient;
  var onPlaylistCreated = Props.onPlaylistCreated;
  var match = React.useState(function () {
        return "";
      });
  var setPlaylistName = match[1];
  var playlistName = match[0];
  var match$1 = Recoil.useRecoilState(RecommendationsState$Spotigen.recommendationsState);
  var setItems = match$1[1];
  var items = match$1[0];
  var user = Recoil.useRecoilValue(UserState$Spotigen.userState);
  var changePlaylistName = function ($$event) {
    return Curry._1(setPlaylistName, $$event.target.value);
  };
  var goBack = function (param) {
    return Curry._1(setItems, (function (param) {
                  return [];
                }));
  };
  var createPlaylist = function (param) {
    return Future.get(Curry._3(spotifyClient.createPlaylist, user.id, playlistName, items), (function (response) {
                  Curry._1(setItems, (function (param) {
                          return [];
                        }));
                  return Curry._1(onPlaylistCreated, undefined);
                }));
  };
  return React.createElement("div", undefined, React.createElement(Core.Button, {
                  onClick: goBack,
                  children: "Back to selection",
                  startIcon: React.createElement(ArrowBack, {})
                }), React.createElement(Core.Typography, {
                  children: "Recommendations",
                  variant: "h4"
                }), React.createElement("div", {
                  className: "hbox",
                  style: {
                    margin: "16px auto"
                  }
                }, React.createElement(Core.TextField, {
                      style: {
                        marginRight: "8px",
                        flex: "1"
                      },
                      onChange: changePlaylistName,
                      placeholder: "Playlist Name",
                      value: MaterialUi_TextField.Value.string(playlistName),
                      variant: "outlined"
                    }), React.createElement(Core.Button, {
                      onClick: createPlaylist,
                      children: "Save in Spotify",
                      color: "primary",
                      disabled: playlistName.length === 0,
                      variant: "contained"
                    })), Belt_Array.map(items, (function (item) {
                    var match = ItemUtil$Spotigen.extractItemInfo(item);
                    return React.createElement(ItemComponent$Spotigen.make, {
                                item: item,
                                showGenreLabel: false,
                                control: null,
                                key: match[1]
                              });
                  })));
}

var make = Recommendations;

export {
  make ,
  
}
/* react Not a pure module */
