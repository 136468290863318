

import * as React from "react";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Core from "@material-ui/core";

import './Item.css';
;

function ItemComponent(Props) {
  var item = Props.item;
  var showGenreLabel = Props.showGenreLabel;
  var control = Props.control;
  var match;
  switch (item.TAG | 0) {
    case /* Genre */0 :
        match = [
          item._0.name,
          "",
          showGenreLabel ? "Genre" : ""
        ];
        break;
    case /* Artist */1 :
        var match$1 = item._0;
        match = [
          match$1.name,
          match$1.image,
          "Artist"
        ];
        break;
    case /* Track */2 :
        var match$2 = item._0;
        match = [
          match$2.name,
          match$2.image,
          "Track by " + Belt_Array.joinWith(match$2.artists, ", ", (function (artist) {
                  return artist.name;
                }))
        ];
        break;
    
  }
  var description = match[2];
  var image = match[1];
  var name = match[0];
  var tmp = image === "" ? React.createElement(Core.Avatar, {
          children: name[0],
          className: "cover",
          variant: "square"
        }) : React.createElement("img", {
          className: "cover",
          src: image
        });
  var tmp$1 = description === "" ? null : React.createElement(Core.Typography, {
          children: description,
          className: "description"
        });
  return React.createElement("div", {
              className: "item"
            }, tmp, React.createElement("div", {
                  className: "caption"
                }, React.createElement(Core.Typography, {
                      children: name
                    }), tmp$1), control);
}

var make = ItemComponent;

export {
  make ,
  
}
/*  Not a pure module */
