

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Core from "@material-ui/core";
import * as ItemComponent$Spotigen from "../../item/ItemComponent.bs.js";
import Clear from "@material-ui/icons/Clear";

function SelectionItem(Props) {
  var item = Props.item;
  var id = Props.id;
  var onRemove = Props.onRemove;
  var removeItem = function (param) {
    return Curry._1(onRemove, id);
  };
  return React.createElement(ItemComponent$Spotigen.make, {
              item: item,
              showGenreLabel: true,
              control: React.createElement(Core.IconButton, {
                    onClick: removeItem,
                    children: React.createElement(Clear, {})
                  })
            });
}

var make = SelectionItem;

export {
  make ,
  
}
/* react Not a pure module */
