

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Recoil from "recoil";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Core from "@material-ui/core";
import * as ItemUtil$Spotigen from "../../../util/ItemUtil.bs.js";
import * as SearchItem$Spotigen from "../search/SearchItem.bs.js";
import * as MaterialUi_TextField from "@jsiebern/bs-material-ui/src/MaterialUi_TextField.bs.js";
import * as SelectionState$Spotigen from "../../../state/SelectionState.bs.js";

function Genres(Props) {
  var items = Props.items;
  var match = React.useState(function () {
        return "";
      });
  var setSearchTerm = match[1];
  var searchTerm = match[0];
  var selected = Recoil.useRecoilValue(SelectionState$Spotigen.selectionState);
  var changeSearchTerm = function ($$event) {
    return Curry._1(setSearchTerm, $$event.target.value);
  };
  return React.createElement("div", undefined, React.createElement("form", {
                  autoComplete: "off"
                }, React.createElement(Core.TextField, {
                      className: "input",
                      onChange: changeSearchTerm,
                      placeholder: "Search",
                      type: "search",
                      value: MaterialUi_TextField.Value.string(searchTerm),
                      variant: "outlined"
                    })), React.createElement("div", {
                  className: "spacing"
                }, searchTerm.length !== 0 ? Belt_Array.map(Belt_Array.keep(Belt_Array.map(items, ItemUtil$Spotigen.extractItemInfo), (function (param) {
                              if (searchTerm !== "") {
                                return param[2].toLowerCase().includes(searchTerm);
                              } else {
                                return false;
                              }
                            })), (function (param) {
                          var id = param[1];
                          var added = Belt_Array.some(selected, (function (itemToCheck) {
                                  var match = ItemUtil$Spotigen.extractItemInfo(itemToCheck);
                                  return match[1] === id;
                                }));
                          return React.createElement(SearchItem$Spotigen.make, {
                                      item: param[0],
                                      added: added,
                                      key: id
                                    });
                        })) : React.createElement(Core.Typography, {
                        children: "Enter a search term to select genres",
                        style: {
                          color: "grey"
                        }
                      })));
}

var make = Genres;

export {
  make ,
  
}
/* react Not a pure module */
