

import * as React from "react";
import LpimageSvg from "./lpimage.svg";
import * as Core from "@material-ui/core";
import * as AuthorizationService$Spotigen from "../../services/authorization/AuthorizationService.bs.js";

var logo = LpimageSvg;

function Auth(Props) {
  var connectSpotify = function (param) {
    return AuthorizationService$Spotigen.authorize(undefined);
  };
  return React.createElement("div", {
              className: "landing"
            }, React.createElement("div", {
                  className: "spacing"
                }, React.createElement("img", {
                      style: {
                        maxWidth: "70%"
                      },
                      alt: "logo",
                      src: logo
                    }), React.createElement(Core.Typography, {
                      children: "Welcome to spotigen",
                      className: "spacing",
                      variant: "h4"
                    }), React.createElement("br", undefined), React.createElement(Core.Typography, {
                      children: "Create playlists with recommendations based on your favourite tracks, artists and genres.",
                      className: "spacing",
                      style: {
                        marginBottom: "64px"
                      }
                    }), React.createElement(Core.Typography, {
                      children: null,
                      className: "spacing",
                      style: {
                        marginBottom: "16px"
                      }
                    }, "By connecting your Spotify account, you agree to our ", React.createElement(Core.Link, {
                          children: "privacy policy",
                          href: "https://bitvictory.de/spotigen/privacy.md",
                          target: "_blank"
                        })), React.createElement(Core.Button, {
                      onClick: connectSpotify,
                      children: "Login with Spotify",
                      color: "primary",
                      variant: "contained"
                    })));
}

var make = Auth;

export {
  logo ,
  make ,
  
}
/* logo Not a pure module */
