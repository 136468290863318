

import * as Future from "rescript-future/src/Future.bs.js";
import * as Js_dict from "bs-platform/lib/es6/js_dict.js";
import * as Js_math from "bs-platform/lib/es6/js_math.js";
import * as $$Request from "rescript-request/src/Request.bs.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Belt_HashMapString from "bs-platform/lib/es6/belt_HashMapString.js";
import * as SpotifyEnv$Spotigen from "../utils/SpotifyEnv.bs.js";
import * as ModelMapper$Spotigen from "../utils/ModelMapper.bs.js";
import * as RequestMapper$Spotigen from "../utils/RequestMapper.bs.js";

function mapResponseToItem(response, totalSeedSize) {
  var status = response.status;
  if (status !== 200) {
    return {
            TAG: /* Error */1,
            _0: /* ResponseError */{
              _0: {
                message: "Failed request with $status"
              }
            }
          };
  }
  var response$1 = response.response;
  if (response$1 === undefined) {
    return {
            TAG: /* Error */1,
            _0: /* Empty */0
          };
  }
  var numberOfTracksToSelect = Js_math.ceil_int(response$1.seeds.length / totalSeedSize * response$1.tracks.length);
  var tracks = Belt_Array.slice(Belt_Array.shuffle(Belt_Array.map(Belt_Array.map(response$1.tracks, ModelMapper$Spotigen.mapSearchTrackToItemTrack), (function (t) {
                  return {
                          TAG: /* Track */2,
                          _0: t
                        };
                }))), 0, numberOfTracksToSelect);
  return {
          TAG: /* Ok */0,
          _0: tracks
        };
}

function getSeedItems(items, itemType) {
  return Belt_Array.keep(Belt_Array.map(items, (function (item) {
                      switch (itemType) {
                        case "artist" :
                            switch (item.TAG | 0) {
                              case /* Artist */1 :
                                  return item._0.id;
                              case /* Genre */0 :
                              case /* Track */2 :
                                  return "";
                              
                            }
                        case "genre" :
                            switch (item.TAG | 0) {
                              case /* Genre */0 :
                                  return item._0.id;
                              case /* Artist */1 :
                              case /* Track */2 :
                                  return "";
                              
                            }
                        case "track" :
                            switch (item.TAG | 0) {
                              case /* Genre */0 :
                              case /* Artist */1 :
                                  return "";
                              case /* Track */2 :
                                  return item._0.id;
                              
                            }
                        default:
                          return "";
                      }
                    })), (function (id) {
                  return id.length !== 0;
                })).join(",");
}

function generateQueryParam(items, amount) {
  var queryParam = Belt_HashMapString.fromArray([]);
  Belt_HashMapString.set(queryParam, "limit", String(amount));
  var seedArtists = getSeedItems(items, "artist");
  if (seedArtists.length !== 0) {
    Belt_HashMapString.set(queryParam, "seed_artists", seedArtists);
  }
  var seedTracks = getSeedItems(items, "track");
  if (seedTracks.length !== 0) {
    Belt_HashMapString.set(queryParam, "seed_tracks", seedTracks);
  }
  var seedGenres = getSeedItems(items, "genre");
  if (seedGenres.length !== 0) {
    Belt_HashMapString.set(queryParam, "seed_genres", seedGenres);
  }
  return String(new URLSearchParams(Belt_HashMapString.toArray(queryParam)));
}

function init(token) {
  var authHeader = Js_dict.fromArray([[
          "Authorization",
          "Bearer " + token
        ]]);
  return function (items) {
    var shuffledItems = Belt_Array.shuffle(items);
    var requests = Belt_Array.makeBy(Js_math.ceil_int(shuffledItems.length / 5), (function (idx) {
            var offset = Math.imul(idx, 5);
            var numberOfRemainingItems = shuffledItems.length - offset | 0;
            var n = numberOfRemainingItems > 5 ? 5 : numberOfRemainingItems;
            var subset = Belt_Array.slice(shuffledItems, offset, n);
            var queryParam = generateQueryParam(subset, 20);
            return $$Request.make(SpotifyEnv$Spotigen.recommendationUrl + queryParam, undefined, /* JsonAsAny */5, undefined, Caml_option.some(authHeader), undefined, undefined, undefined, undefined, undefined);
          }));
    return Future.map(Future.all(requests), undefined, (function (results) {
                  var resultMap = Belt_Array.map(results, (function (result) {
                          if (result.TAG === /* Ok */0) {
                            return mapResponseToItem(result._0, items.length);
                          } else {
                            return {
                                    TAG: /* Error */1,
                                    _0: RequestMapper$Spotigen.mapError(result._0)
                                  };
                          }
                        }));
                  var err = resultMap.find(function (result) {
                        if (result.TAG === /* Ok */0) {
                          return false;
                        } else {
                          return true;
                        }
                      });
                  if (err !== undefined) {
                    return err;
                  } else {
                    return {
                            TAG: /* Ok */0,
                            _0: Belt_Array.concatMany(Belt_Array.map(resultMap, (function (result) {
                                        if (result.TAG === /* Ok */0) {
                                          return result._0;
                                        } else {
                                          return [];
                                        }
                                      })))
                          };
                  }
                }));
  };
}

export {
  mapResponseToItem ,
  getSeedItems ,
  generateQueryParam ,
  init ,
  
}
/* SpotifyEnv-Spotigen Not a pure module */
