

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Recoil from "recoil";
import * as Person$Spotigen from "../person/Person.bs.js";
import * as Core from "@material-ui/core";
import * as UserState$Spotigen from "../../state/UserState.bs.js";
import * as TokenState$Spotigen from "../../state/TokenState.bs.js";

function UserComponent(Props) {
  var match = Recoil.useRecoilState(TokenState$Spotigen.tokenState);
  var setToken = match[1];
  var user = Recoil.useRecoilValue(UserState$Spotigen.userState);
  var logout = function ($$event) {
    $$event.preventDefault();
    return Curry._1(setToken, (function (param) {
                  return "";
                }));
  };
  return React.createElement("div", {
              className: "hbox"
            }, React.createElement(Person$Spotigen.make, {
                  name: user.name,
                  image: user.image,
                  item: React.createElement(Core.Typography, {
                        children: null
                      }, user.name + " | ", React.createElement(Core.Link, {
                            children: "Logout",
                            onClick: logout,
                            href: "#"
                          }))
                }));
}

var make = UserComponent;

export {
  make ,
  
}
/* react Not a pure module */
