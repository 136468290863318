

import * as Future from "rescript-future/src/Future.bs.js";
import * as Js_dict from "bs-platform/lib/es6/js_dict.js";
import * as $$Request from "rescript-request/src/Request.bs.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as SpotifyEnv$Spotigen from "../utils/SpotifyEnv.bs.js";
import * as RequestMapper$Spotigen from "../utils/RequestMapper.bs.js";

function mapGenresKeyToGenresItem(gKey) {
  return {
          TAG: /* Genre */0,
          _0: {
            id: gKey,
            name: gKey.replace(/\-/g, " ").toUpperCase()
          }
        };
}

function mapResponseToItem(response) {
  var status = response.status;
  if (status !== 200) {
    return {
            TAG: /* Error */1,
            _0: /* ResponseError */{
              _0: {
                message: "Failed request with $status"
              }
            }
          };
  }
  var response$1 = response.response;
  if (response$1 === undefined) {
    return {
            TAG: /* Error */1,
            _0: /* Empty */0
          };
  }
  var genres = Belt_Array.map(response$1.genres, mapGenresKeyToGenresItem);
  return {
          TAG: /* Ok */0,
          _0: genres
        };
}

function init(token) {
  var authHeader = Js_dict.fromArray([[
          "Authorization",
          "Bearer " + token
        ]]);
  return function (param) {
    return Future.mapResult(Future.mapError($$Request.make(SpotifyEnv$Spotigen.genresUrl, undefined, /* JsonAsAny */5, undefined, Caml_option.some(authHeader), undefined, undefined, undefined, undefined, undefined), true, RequestMapper$Spotigen.mapError), true, mapResponseToItem);
  };
}

export {
  mapGenresKeyToGenresItem ,
  mapResponseToItem ,
  init ,
  
}
/* SpotifyEnv-Spotigen Not a pure module */
