

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Recoil from "recoil";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Core from "@material-ui/core";
import * as ItemUtil$Spotigen from "../../../util/ItemUtil.bs.js";
import Add from "@material-ui/icons/Add";
import * as ItemComponent$Spotigen from "../../item/ItemComponent.bs.js";
import * as SelectionState$Spotigen from "../../../state/SelectionState.bs.js";
import Check from "@material-ui/icons/Check";

function SearchItem(Props) {
  var item = Props.item;
  var added = Props.added;
  var match = Recoil.useRecoilState(SelectionState$Spotigen.selectionState);
  var setSelected = match[1];
  var addItem = function (param) {
    return Curry._1(setSelected, (function (currentSelection) {
                  return Belt_Array.concat(currentSelection, [item]);
                }));
  };
  var removeItem = function (param) {
    var match = ItemUtil$Spotigen.extractItemInfo(item);
    var itemId = match[1];
    return Curry._1(setSelected, (function (currentSelection) {
                  return Belt_Array.keep(currentSelection, (function (item) {
                                var match = ItemUtil$Spotigen.extractItemInfo(item);
                                return match[1] !== itemId;
                              }));
                }));
  };
  var control = added ? React.createElement(Core.IconButton, {
          onClick: removeItem,
          children: React.createElement(Check, {})
        }) : React.createElement(Core.IconButton, {
          onClick: addItem,
          children: React.createElement(Add, {})
        });
  return React.createElement(ItemComponent$Spotigen.make, {
              item: item,
              showGenreLabel: false,
              control: control
            });
}

var make = SearchItem;

export {
  make ,
  
}
/* react Not a pure module */
