

import * as Future from "rescript-future/src/Future.bs.js";
import * as Js_dict from "bs-platform/lib/es6/js_dict.js";
import * as $$Request from "rescript-request/src/Request.bs.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Belt_HashMapString from "bs-platform/lib/es6/belt_HashMapString.js";
import * as SpotifyEnv$Spotigen from "../utils/SpotifyEnv.bs.js";
import * as RequestMapper$Spotigen from "../utils/RequestMapper.bs.js";

function createPlaylistMapping(response) {
  var status = response.status;
  if (status !== 201) {
    console.log(response);
    return {
            TAG: /* Error */1,
            _0: /* ResponseError */{
              _0: {
                message: "Failed request to create Playlist"
              }
            }
          };
  }
  var response$1 = response.response;
  if (response$1 !== undefined) {
    return {
            TAG: /* Ok */0,
            _0: Caml_option.valFromOption(response$1)
          };
  } else {
    return {
            TAG: /* Error */1,
            _0: /* Empty */0
          };
  }
}

function createPlaylist(userId, playlistName, authHeader) {
  var dict = {};
  dict["name"] = playlistName;
  dict["public"] = false;
  var body = JSON.stringify(dict);
  console.log(body);
  return Future.mapResult(Future.mapError($$Request.make(SpotifyEnv$Spotigen.usersUrl + userId + SpotifyEnv$Spotigen.playlistsEndpoint, "POST", /* JsonAsAny */5, body, Caml_option.some(authHeader), undefined, undefined, undefined, undefined, undefined), true, RequestMapper$Spotigen.mapError), true, createPlaylistMapping);
}

function getTrackUris(items) {
  return Belt_Array.keep(Belt_Array.map(items, (function (item) {
                      switch (item.TAG | 0) {
                        case /* Genre */0 :
                        case /* Artist */1 :
                            return ;
                        case /* Track */2 :
                            return item._0.uri;
                        
                      }
                    })), (function (artist) {
                  return artist !== undefined;
                })).join(",");
}

function generateTrackQueryParam(items) {
  var queryParam = Belt_HashMapString.fromArray([]);
  var seedTracks = getTrackUris(items);
  if (seedTracks.length !== 0) {
    Belt_HashMapString.set(queryParam, "uris", seedTracks);
  }
  return String(new URLSearchParams(Belt_HashMapString.toArray(queryParam)));
}

function addTracksMapping(response) {
  var status = response.status;
  if (status !== 200) {
    return {
            TAG: /* Error */1,
            _0: /* ResponseError */{
              _0: {
                message: "Failed request to add tracks to playlist"
              }
            }
          };
  }
  var response$1 = response.response;
  if (response$1 !== undefined) {
    return {
            TAG: /* Ok */0,
            _0: Caml_option.valFromOption(response$1)
          };
  } else {
    return {
            TAG: /* Error */1,
            _0: /* Empty */0
          };
  }
}

function addTracks(playlistId, items, authHeader) {
  var query = generateTrackQueryParam(items);
  return Future.mapResult(Future.mapError($$Request.make(SpotifyEnv$Spotigen.playlistsUrl + playlistId + SpotifyEnv$Spotigen.tracksEndpoint + query, "POST", /* JsonAsAny */5, undefined, Caml_option.some(authHeader), undefined, undefined, undefined, undefined, undefined), true, RequestMapper$Spotigen.mapError), true, addTracksMapping);
}

function init(token) {
  var authHeader = Js_dict.fromArray([[
          "Authorization",
          "Bearer " + token
        ]]);
  return function (userId, playlistName, items) {
    return Future.map(Future.mapOk(createPlaylist(userId, playlistName, authHeader), true, (function (createdPlaylist) {
                      return addTracks(createdPlaylist.id, items, authHeader);
                    })), true, (function (param) {
                  return "";
                }));
  };
}

export {
  createPlaylistMapping ,
  createPlaylist ,
  getTrackUris ,
  generateTrackQueryParam ,
  addTracksMapping ,
  addTracks ,
  init ,
  
}
/* SpotifyEnv-Spotigen Not a pure module */
