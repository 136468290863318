

import * as React from "react";
import * as Recoil from "recoil";
import * as MainContainer$Spotigen from "../main/MainContainer.bs.js";

import './App.css';
;

function App(Props) {
  return React.createElement(Recoil.RecoilRoot, {
              children: React.createElement(MainContainer$Spotigen.make, {})
            });
}

var make = App;

export {
  make ,
  
}
/*  Not a pure module */
