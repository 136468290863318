

import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as UrlType$Spotigen from "../../types/UrlType.bs.js";
import * as Belt_HashMapString from "bs-platform/lib/es6/belt_HashMapString.js";
import * as SpotifyEnv$Spotigen from "../utils/SpotifyEnv.bs.js";

function authorize(param) {
  window.location.replace(SpotifyEnv$Spotigen.authorizeUrl);
  
}

function getTokenFromCallback(param) {
  var parameter = Belt_HashMapString.fromArray(Belt_Array.map(Belt_Array.keep(Belt_Array.map(window.location.hash.slice(1).split("&"), (function (s) {
                      return s.split("=");
                    })), (function (a) {
                  return a.length === 2;
                })), (function (p) {
              return [
                      p[0],
                      String(p[1])
                    ];
            })));
  var s = Belt_HashMapString.get(parameter, "state");
  if (s === undefined) {
    return ;
  }
  if (s !== SpotifyEnv$Spotigen.stateId(undefined)) {
    return ;
  }
  UrlType$Spotigen.removeUrlAnchor(undefined);
  var t = Belt_HashMapString.get(parameter, "access_token");
  if (t !== undefined) {
    return t;
  }
  
}

export {
  authorize ,
  getTokenFromCallback ,
  
}
/* SpotifyEnv-Spotigen Not a pure module */
