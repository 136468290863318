

import * as UserService$Spotigen from "./user/UserService.bs.js";
import * as GenresService$Spotigen from "./genres/GenresService.bs.js";
import * as SearchService$Spotigen from "./search/SearchService.bs.js";
import * as PlaylistService$Spotigen from "./playlist/PlaylistService.bs.js";
import * as RecommendationService$Spotigen from "./recomendations/RecommendationService.bs.js";
import * as PersonalizationService$Spotigen from "./personalization/PersonalizationService.bs.js";

function init(token) {
  return {
          getSearch: SearchService$Spotigen.init(token),
          getGenres: GenresService$Spotigen.init(token),
          getRecommendation: RecommendationService$Spotigen.init(token),
          createPlaylist: PlaylistService$Spotigen.init(token),
          getUser: UserService$Spotigen.init(token),
          getTopTracks: PersonalizationService$Spotigen.initTopTracks(token),
          getTopArtists: PersonalizationService$Spotigen.initTopArtist(token)
        };
}

export {
  init ,
  
}
/* UserService-Spotigen Not a pure module */
