

import * as React from "react";
import * as Person$Spotigen from "../person/Person.bs.js";
import * as Core from "@material-ui/core";

function About(Props) {
  return React.createElement("div", {
              className: "footer"
            }, React.createElement(Core.Typography, {
                  children: "Created with ❤️ in Mannheim".normalize()
                }), React.createElement(Core.Typography, {
                  children: null
                }, React.createElement(Core.Link, {
                      children: "Github",
                      href: "https://github.com/teambitvictory/spotigen",
                      target: "_blank"
                    }), " | ", React.createElement(Core.Link, {
                      children: "Privacy Policy",
                      href: "https://bitvictory.de/spotigen/privacy.md",
                      target: "_blank"
                    }), " | ", React.createElement(Core.Link, {
                      children: "Imprint",
                      href: "https://bitvictory.de/spotigen/imprint.md",
                      target: "_blank"
                    })), React.createElement("div", {
                  style: {
                    margin: "auto",
                    width: "180px"
                  }
                }, React.createElement(Person$Spotigen.make, {
                      name: "Damien Jochim",
                      image: "https://pbs.twimg.com/profile_images/1284521496438874112/LDA9UyiN_400x400.jpg",
                      item: React.createElement(Core.Link, {
                            children: "@damien_jochim",
                            href: "https://twitter.com/damien_jochim",
                            target: "_blank"
                          })
                    }), React.createElement(Person$Spotigen.make, {
                      name: "Stefan Blamberg",
                      image: "https://pbs.twimg.com/profile_images/1328445229221949442/1uPmXD13_400x400.jpg",
                      item: React.createElement(Core.Link, {
                            children: "@StefanBlamberg",
                            href: "https://twitter.com/StefanBlamberg",
                            target: "_blank"
                          })
                    })));
}

var make = About;

export {
  make ,
  
}
/* react Not a pure module */
