


function extractItemInfo(item) {
  var match;
  var exit = 0;
  switch (item.TAG | 0) {
    case /* Genre */0 :
    case /* Artist */1 :
        exit = 1;
        break;
    case /* Track */2 :
        var match$1 = item._0;
        match = [
          match$1.id,
          match$1.name
        ];
        break;
    
  }
  if (exit === 1) {
    var match$2 = item._0;
    match = [
      match$2.id,
      match$2.name
    ];
  }
  return [
          item,
          match[0],
          match[1]
        ];
}

export {
  extractItemInfo ,
  
}
/* No side effect */
